.schematic-diagram {
  height: calc(var(--app-height) - 173px);
  width: 100%;

  &.schematic-diagram--read-only {
    border-bottom: 1px solid #e3e3e3;
    height: calc(var(--app-height) - 445px);
  }

  .schematic-diagram-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
