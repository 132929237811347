@use "../../global-styles/colors";

.diagram-menu {
  display: flex;
  justify-content: center;
  margin: 5px 0px;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 5px;

  .diagram-menu-container {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    padding: 5px;
    font-size: 25px;
    gap: 10px;
  }
}
