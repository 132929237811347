@use "../../global-styles/colors";
@use "../../global-styles/settings";

.select-menu-wrapper {
  position: relative;
  user-select: none;
  flex: 1;
  min-width: 300px;
  border: none;
}

.menu-select {
  position: relative;
  display: flex;
  flex-direction: column;
  border: none;
}

.menu-select__trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 14px;
  height: settings.$basic-height;
  line-height: settings.$basic-height;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid colors.$border-color;
  border-radius: 4px;

  &.disabled {
    background-color: colors.$disabled-color;
    cursor: not-allowed;

    &:hover {
      background-color: colors.$disabled-color;
    }
  }

  &:hover {
    background-color: colors.$hover-color;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
    overflow-x: hidden;
  }
}

.menu-select.open .menu-select__trigger {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.menu-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0px;
  right: 0px;
  border: 1px solid colors.$border-color;
  border-top: 0;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  max-height: 175px;
  overflow-y: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.menu-select.open .menu-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.menu-option {
  position: relative;
  display: block;
  padding: 0px 10px;
  font-size: 14px;
  line-height: Calc(settings.$basic-height - 5px);
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 1px solid colors.$border-color;

  &:last-child {
    border-bottom: none;
  }
}

.menu-option-search {
  border: none;
  border-bottom: 1px solid colors.$border-color;
  width: 100%;
  height: settings.$basic-height;
  font-size: 14px;
  padding: 10px;
}

.menu-option:hover {
  cursor: pointer;
  background-color: colors.$hover-color;
}

.menu-option.selected {
  background-color: colors.$select-color;
}

.menu-option.disabled {
  background-color: colors.$hover-color;
  &:hover {
    cursor: not-allowed;
  }
}

.arrow {
  position: relative;
  height: 12px;
  width: 12px;
}

.arrow::before,
.arrow::after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 0.15rem;
  height: 100%;
  transition: all 0.3s;
}

.arrow::before {
  left: -3.5px;
  transform: rotate(45deg);
  background-color: black;
}

.arrow::after {
  left: 3.5px;
  transform: rotate(-45deg);
  background-color: black;
}

.open .arrow::before {
  left: -3.5px;
  transform: rotate(-45deg);
}

.open .arrow::after {
  left: 3.5px;
  transform: rotate(45deg);
}
