.overlay {
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 0px 30px;
}
