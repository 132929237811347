@use "../../global-styles/colors";

.top-menu {
  background-color: white;
  border-bottom: 1px solid colors.$border-color;
  padding: 7px 25px;
  font-size: 24px;

  .top-menu-body {
    display: flex;
    align-content: center;

    .top-menu-item {
      display: flex;
      flex: 1;
      align-items: center;

      &.top-menu-item__center {
        flex: 4;
        justify-content: center;
        margin-left: 5px;
        margin-right: 10px;
      }

      &.top-menu-item__end {
        flex-direction: row-reverse;
        gap: 20px;
      }
    }
  }

  .side-menu-icon {
    &:hover {
      cursor: pointer;
    }
  }
}
