@use "../../../global-styles/colors";

.equipment-selector {
  display: flex;
  width: 100%;
}

.equipment-selector-table {
  border: 1px solid colors.$border-color;
  border-radius: 4px;
  width: 100%;

  .equipment-selector-table-header {
    .equipment-selector-table-header__row {
      display: grid;
      grid-template-columns: 3fr 2fr 0.5fr 2fr 3fr;

      p {
        font-weight: bold;
        font-size: 14px;
        border-right: 1px solid colors.$border-color;
        padding: 10px 15px;
        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .equipment-selector-table-body {
    max-height: 40vh;
    overflow-y: overlay;

    .equipment-selector-table-body__row {
      display: grid;
      grid-template-columns: 3fr 2fr 0.5fr 2fr 3fr;
      border-bottom: 1px solid colors.$border-color;

      &.row-invalid {
        background-color: colors.$red;
      }

      &:first-child {
        border-top: 1px solid colors.$border-color;
      }

      &:last-child {
        border-bottom: none;
      }

      p {
        font-size: 14px;
        border-right: 1px solid colors.$border-color;
        padding: 10px 15px;

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
