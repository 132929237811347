$grey: grey;
$dark-grey: #4c5355;
$lighter-grey: #f2f2f2;
$light-grey: #e3e3e3;
$ultra-light-grey: #f9f9f9;
$hover-color: #f2f2f2;
$hover-colorful: #01dcff;
$hover-selected: rgba(25, 118, 210, 0.2);
$select-color: rgba(25, 118, 210, 0.08);
$text-primary: black;
$border-color: #e3e3e3;
$dark-border-color: #e3e3e3;
$background-color: white;
$header-color: #f2f2f2;
$error-color: #fc6e6e;
$red: #fc6e6e;
$success-color: #659165;
$green: #659165;
$blue: rgba(25, 118, 210, 1);
$disabled-color: #f2f2f2;
