@use "../../global-styles/colors";
@use "../../global-styles/settings";

.map-diagram {
}

@media only screen and (min-width: 1200px) {
  .map-diagram--mobile {
    display: none;
  }

  .map-diagram--desktop {
    display: flex;
    flex-wrap: wrap;
    height: calc(var(--app-height) - settings.$top-menu-height);

    .hide-container {
      position: absolute;
      right: 0;
      padding: 5px;
      z-index: 1;

      button {
        border: none;
        padding: 5px;
        border-radius: 4px;
      }
    }

    .container {
      flex: 1;
      height: calc(var(--app-height) - settings.$top-menu-height);

      &:first-of-type {
        border-right: 1px solid colors.$border-color;
      }

      &:last-of-type {
        border-left: 1px solid colors.$border-color;
        overflow-y: scroll;
      }

      &.hide {
        display: none;
      }
    }
  }
}

// Tablet and below
@media only screen and (max-device-width: 1200px) {
  .map-diagram--desktop {
    display: none;
  }

  .map-diagram--mobile {
  }
}

// Tablet retina and beloq
@media only screen and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) {
  .map-diagram--desktop {
    display: none;
  }

  .map-diagram--mobile {
  }
}
