.modal {
  background-color: #fefefe;
  padding: 15px;
  border: none;
  border-radius: 4px;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
  animation: fadein 0.1s;

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    95% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.modal-header {
  display: flex;
  flex: 1;
  padding-bottom: 15px;

  .modal-header__title {
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    align-self: center;
  }

  .close {
    font-size: 28px;
    font-weight: bold;
    justify-self: flex-end;

    &:hover {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.modal-body {
  flex: 1;
  flex-basis: 100%;
}

.modal-body--max-size {
  max-height: 600px;
  overflow-y: auto;
}
