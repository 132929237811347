@use "../../../global-styles/colors";

.disconnect-fiber-editor {
}

.disconnect-fiber-editor__title {
  font-size: 16px;
}

.disconnect-fiber-editor-container {
  border: 1px solid colors.$border-color;
  border-radius: 4px;
}

.disconnect-fiber-editor-container-header,
.disconnect-fiber-editor-container-body-line {
  display: grid;
  grid-template-columns:
    minmax(0, 1fr) repeat(3, minmax(0, 3fr))
    repeat(3, minmax(0, 3fr));
  grid-auto-flow: column;
  overflow: hidden;
}

.disconnect-fiber-editor-container-header {
  width: 100%;
}

.disconnect-fiber-editor-container-header-item {
  font-weight: bold;
  border-right: 1px solid colors.$border-color;
  padding: 10px;
  overflow-wrap: break-word;

  &:last-of-type {
    border-right: none;
  }
}

.disconnect-fiber-editor-container-body {
  font-size: 14px;
  max-height: 60vh;
  overflow-y: auto;
  overflow: overlay;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.disconnect-fiber-editor-container-body-line {
  flex-basis: 100%;
  width: 100%;
  border-bottom: 1px solid colors.$border-color;

  &:last-of-type {
    border-bottom: none;
  }
}

.disconnect-fiber-editor-container-body-line-item {
  border-right: 1px solid colors.$border-color;
  padding: 10px;
  width: 100%;
  overflow-wrap: break-word;

  &:first-of-type {
    align-items: center;
    justify-items: center;
    height: 100%;
    display: flex;
  }

  &:last-of-type {
    padding-right: 20px;
    border-right: none;
  }
}
