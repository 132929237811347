@use "../../global-styles/colors";
@use "../../global-styles/settings";

.default-button {
  width: 100%;
  background-color: white;
  border-radius: 0;
  border: none;
  padding: 15px 20px;
  font-size: 16px;
  border: 1px solid colors.$border-color;
  flex: 1;
  padding: 0;
  min-width: 300px;
  height: settings.$basic-height;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: colors.$hover-color;
  }

  &[disabled] {
    background-color: colors.$disabled-color;
    &:hover {
      cursor: not-allowed;
    }
  }
}
