@use "colors";
@use "settings";

*,
*:before,
*:after {
  box-sizing: border-box;
}

:focus {
  outline: 0;
}

html,
body,
#root {
  min-height: var(--app-height);
  overflow: overlay;
}

header {
  position: fixed;
  width: 100%;
  z-index: 1;
}

body {
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #fcfcfc;
  overflow-x: hidden;
  min-height: var(--app-height);
}

.main-container {
  padding-top: settings.$top-menu-height;
  display: flex;
  transform: translateX(0px);
  color: colors.$text-primary;
  justify-content: center;
  min-height: var(--app-height);
  overflow: auto;

  &.side-menu-open {
    width: 100%;
  }
}

.page-container {
  max-width: 1400px;
  width: 100%;
  height: 100%;
}

.page-container-fitted {
  border-radius: 4px;
  padding: 20px;
  background-color: white;
  border: 1px solid colors.$border-color;
  margin: 10px;
  height: 100%;
}

.container-max-size {
  max-width: 1500px;
}

.container-medium-size {
  max-width: 800px;
}

.container-center {
  margin: 0px auto;
}

.container-background {
  border-radius: 4px;
  padding: 20px;
  background-color: white;
  border: 1px solid colors.$border-color;
  margin: 20px;
}

.block {
  border-radius: 4px;
  background-color: white;
  border: 1px solid colors.$border-color;
  margin: 0px 0px 20px 0px;
  padding: 15px;
}

.block-title {
  font-size: 16px;
  padding-bottom: 15px;
  font-weight: bold;
}

.block-title__big {
  font-size: 18px;
}

.block-title__underline {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid colors.$border-color;
}

.gap-default {
  gap: 10px;
}

.full-row {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;

  &:last-child {
    padding-bottom: 0;
  }

  &.center-items {
    justify-content: center;
  }
}

.full-row-group {
  border: 1px solid colors.$border-color;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  .full-row-group__title {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 15px;
    border-bottom: 1px solid #ced0d1;
    margin-bottom: 15px;
  }
}

.justify-end {
  justify-content: flex-end;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
}

strong {
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .main-container {
    transition: all 0.3s linear;
    &.side-menu-open {
      transition: all 0.3s linear;
      transform: translateX(settings.$side-menu-size);
      width: calc(100% - #{settings.$side-menu-size});
    }
  }
}

.Toastify__toast--success {
  background-color: colors.$success-color;
  color: white;
}

.Toastify__toast--error {
  background-color: colors.$error-color;
  color: white;
}

.maplibregl-ctrl-group button {
  font-size: 15px;
}

.color-red {
  color: colors.$error-color;
}

.text-red {
  color: colors.$red;
}

.text-green {
  color: colors.$green;
}

.text-center {
  text-align: center;
}

.not-allowed-hover {
  &:hover {
    cursor: not-allowed !important;
  }
}

.maplibregl-popup-close-button {
  font-size: 24px;
}

.maplibregl-popup-content {
  padding: 0;
  border: 1px solid grey;
  margin-bottom: 30px;
}

.maplibregl-popup {
  /* This is a hack, we do not have access to the elment specific style, */
  /* so we have to set it here */
  max-width: 100% !important;
  /* To avoid that the popup is right on top of the selected item. */
  padding-top: 20px;
}

.maplibregl-popup-tip {
  /* We do not want to display the popup tip */
  display: none;
}

.maplibregl-ctrl-bottom-right {
  display: none;
}

.fixed-fit-container {
  position: fixed;
  top: calc(settings.$top-menu-height);
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: calc(var(--app-height) - settings.$top-menu-height);
  z-index: 2;
  background-color: colors.$background-color;
  overflow-y: auto;
}
