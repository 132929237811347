@use "../../global-styles/colors";

.toggle-button {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 7px;
  user-select: none;
  background-color: white;
  width: 45px;
  height: 45px;

  img {
    width: 100%;
    height: 100%;
  }

  &.toggled {
    border: 1px solid greenyellow;

    &:hover {
      border-color: greenyellow;
    }
  }

  &:hover {
    cursor: pointer;
    border: 1px solid colors.$dark-grey;
  }
}
