@use "../../global-styles/settings";
@use "../../global-styles/colors";

.number-picker {
  flex: 1;
  width: 100%;

  .counter-buttons {
    position: absolute;
    right: 0;

    button {
      border: none;
    }
  }

  input {
    width: 100%;
    min-height: settings.$basic-height;
    padding: 0px 20px;
    font-size: 14px;
    border: 1px solid colors.$border-color;
    border-radius: 4px;
  }
}
