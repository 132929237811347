@use "../../../global-styles/colors";

.connectivity-view {
  padding: 20px;
  overflow: auto;
  font-size: 14px;
}

.connectivity-view-container {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid colors.$border-color;
  border-radius: 4px;
  background-color: colors.$header-color;
  min-width: 650px;
}

.connectivity-view__title {
  font-weight: bold;
  padding: 10px;
  padding-right: 50px;
}

.connectivity-view-header {
  flex-basis: 100%;
  font-weight: bold;
  border-top: 1px solid colors.$border-color;
  border-bottom: 1px solid colors.$border-color;
}

.connectivity-view-body {
  flex-basis: 100%;
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.connectivity-view-row {
  display: grid;
  grid-auto-columns: minmax(0, 3fr);
  grid-auto-flow: column;

  border-bottom: 1px solid colors.$border-color;

  &:last-child {
    border-bottom: none;
  }
}

.connectivity-view-row-item {
  border-right: 1px solid colors.$border-color;
  padding: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;

  &:last-child {
    border-right: none;
  }
}

.connectivity-view-row-item__icon {
  padding-right: 20px;
  align-self: center;

  &:hover {
    cursor: pointer;
  }
}

.connectivity-view-trace-view {
  .trace-view-title-header {
    display: flex;
    width: 100%;
    background-color: #f9f9f9;
    border-bottom: 1px solid colors.$border-color;
    padding: 10px;
    align-items: center;
    justify-content: space-between;

    .trace-view-title {
      flex: 1;
      font-weight: bold;
      font-size: 14px;
    }

    .trace-view-actions {
      flex: 1;
      display: flex;
      font-size: 22px;
      flex-direction: row-reverse;
      gap: 10px;
      padding-right: 10px;
      user-select: none;

      .trace-view-actions-action {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .trace-view-grid {
    display: grid;
    grid-auto-columns: minmax(0, 3fr);
    grid-auto-flow: column;
  }

  .trace-view-header {
    border-bottom: 1px solid colors.$border-color;
    .trace-view-header-item {
      background-color: #f9f9f9;
      padding: 10px;
      border-right: 1px solid colors.$border-color;
      align-items: center;
      font-weight: bold;
      &:last-child {
        padding: 10px 20px 10px 10px;
      }

      &:nth-of-type(6) {
        border-right: none;
      }
    }
  }

  .trace-view-body {
    border-bottom: 1px solid colors.$border-color;

    .trace-view-body-row {
      border-bottom: 1px solid colors.$border-color;

      &:last-child {
        border-bottom: none;
      }

      &.trace-view-body-row--selected {
        background-color: colors.$select-color;
      }

      &:hover {
        cursor: pointer;
        background-color: colors.$select-color;
      }

      .trace-view-body-item {
        padding: 10px;
        border-right: 1px solid colors.$border-color;
        align-items: center;
        &:last-child {
          padding: 10px 20px 10px 10px;
        }

        &:nth-of-type(6) {
          border-right: none;
        }
      }
    }
  }
}
