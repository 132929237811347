@use "../../../global-styles/colors";

.general-span-equipment-view {
  padding: 20px;
}

.general-span-equipment-view-container {
  background-color: white;
  border: 1px solid colors.$border-color;
  border-radius: 4px;
}

.general-span-equipment-view-header {
  background-color: colors.$header-color;
  padding: 5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid colors.$border-color;

  p {
    flex: 1;
    font-size: 16px;
    font-weight: bold;
  }
}

.general-span-equipment-view-row-title-header-actions {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;

  .action-button {
    height: 40px;
    width: 40px;
  }
}

.general-span-equipment-view-header-icons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-size: 22px;

  span {
    &:hover {
      cursor: pointer;
      user-select: none;
    }
  }
}

.general-span-equipment-view-body {
  padding: 10px;
}
