@use "../../../../global-styles/colors";

.add-interface {
  .full-row-group {
    border: none;
  }

  .circuit-name-group {
    display: flex;
    gap: 20px;

    span {
      justify-self: center;
      align-self: center;
      font-size: 20px;
      user-select: none;

      &:hover {
        cursor: pointer;
        color: colors.$dark-grey;
      }
    }
  }
}
