@use "../../global-styles/colors";

.tab-view {
  padding: 20px;
}

.tab-view-container {
  background-color: white;
  border: 1px solid colors.$border-color;
  border-radius: 4px;
}

.tab-view-header {
  display: flex;
  border-bottom: 1px solid colors.$border-color;
}

.tab-view-header-tabs {
  flex: 1;
  display: flex;

  .tab-view-header-tab {
    padding: 10px 20px;
    border-right: 1px solid colors.$border-color;
    user-select: none;
    font-weight: bold;

    &.tab-view-header-tab--selected {
      background-color: colors.$select-color;

      &:hover {
        background-color: colors.$hover-selected;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: colors.$select-color;
    }
  }
}

.tab-view-header-actions {
  display: flex;
  flex: 1;
  justify-self: flex-end;
  align-items: center;
  flex-direction: row-reverse;
  padding-right: 5px;

  .tab-view-header-actions-action {
    border: 1px solid colors.$border-color;
    padding: 5px 6px;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      background-color: colors.$hover-color;
    }
  }
}
