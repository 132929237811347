@use "../../global-styles/colors";

.action-button {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 7px;
  user-select: none;
  background-color: white;
  width: 45px;
  height: 45px;

  &.disabled {
    background-color: #e3e3e3;

    &:hover {
      cursor: not-allowed;
    }

    img {
      filter: grayscale(100%);
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid colors.$dark-grey;
  }
}
