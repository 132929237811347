@use "../../global-styles/colors";

.multi-option-action-button-container {
  position: relative;

  .multi-option-action-button {
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    padding: 7px;
    user-select: none;
    background-color: white;
    width: 45px;
    height: 45px;

    &.disabled {
      background-color: #e3e3e3;

      &:hover {
        cursor: not-allowed;
      }

      img {
        filter: grayscale(100%);
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    &:hover {
      cursor: pointer;
      border: 1px solid colors.$dark-grey;
    }
  }

  .multi-option-action-button-menu {
    position: absolute;
    border: 1px solid colors.$border-color;
    background: white;
    font-size: 14px;
    margin-top: 10px;
    z-index: 1;
    border-radius: 4px;
    width: auto;
    white-space: nowrap;
    user-select: none;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        padding: 10px;
        flex-basis: 100%;
        border-bottom: 1px solid colors.$border-color;

        &.disabled {
          color: darkgrey;

          &:hover {
            cursor: not-allowed;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          cursor: pointer;
          background-color: colors.$hover-color;
        }
      }
    }
  }
}
