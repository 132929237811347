@use "../../../global-styles/colors";

.search-menu {
  height: 100%;
  display: flex;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  font-size: 14px;
  position: relative;

  .search-menu-input {
    font-size: 16px;
    width: 100%;
    display: block;
    height: 100%;
    padding: 10px 40px 10px 20px;
    border-radius: 4px;
    border: 1px solid colors.$border-color;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &.search-menu-input__open {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .search-menu-clear {
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    right: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .search-menu-results {
    position: absolute;
    background: white;
    border: 1px solid colors.$border-color;
    border-top: none;
    margin-top: 39px;
    padding: 0px 10px 10px 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 100%;

    ul {
      font-size: 14px;

      li {
        border-bottom: 1px solid colors.$border-color;
        padding: 10px;
        margin: 10px 0px;

        &:hover,
        &:focus {
          cursor: pointer;
          font-weight: bold;
          border-bottom: 1px solid black;
        }
      }
    }
  }
}
