@use "../../../global-styles/colors";

.passage-view {
  padding: 15px;
  overflow: auto;
}

.passage-view-container {
  border: 1px solid colors.$border-color;
  border-radius: 4px;
  background-color: colors.$header-color;
  justify-self: center;
  min-width: 650px;
  width: 100%;
}

.passage-view_title {
  font-weight: bold;
  padding-right: 50px;
  font-size: 16px;
}

.passage-view-row-title-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.passage-view-row-title-header-info {
  display: flex;
}

.passage-view-row-title-header-actions {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;

  .action-button {
    height: 40px;
    width: 40px;
  }
}

.passage-view-row-header {
  font-weight: bold;
  background-color: colors.$header-color;
  border-bottom: none;
  font-size: 14px;
  border-bottom: none;
  border-top: 1px solid colors.$border-color;
  border-bottom: 1px solid colors.$border-color;
}

.passage-view-row-body {
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: white;

  .passage-view-row {
    &.passage-view-row--selected {
      background-color: colors.$select-color;

      &:hover {
        cursor: pointer;
        background-color: colors.$select-color;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: colors.$select-color;
    }
  }
}

.passage-view-grid-size {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.passage-view-row {
  border-bottom: 1px solid colors.$border-color;

  &:last-child {
    border-bottom: none;
  }

  .passage-view-row-item {
    border-right: 1px solid colors.$border-color;

    &:last-child {
      border-right: none;
    }

    p {
      overflow: hidden;
      padding: 10px;
    }
  }
}
