.feature-detail-container {
  display: flex;
  justify-content: center;
  align-content: center;

  .feature-details {
    flex: 1;
    display: flex;
    background: white;
    padding: 5px 20px;
    border-radius: 4px;
    border: 1px solid #e3e3e3;
    max-width: 800px;
    margin: 15px;

    .feature-details-info {
      font-size: 16px;
      padding: 8px;
      flex: 1;
      line-height: 1.5;

      span {
        font-weight: bold;
      }
    }

    .feature-details-actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
    }
  }
}
