@use "../../global-styles/settings";

.route-network-map {
  width: 100%;
  height: 100%;
  visibility: none;

  &.route-network-map--loaded {
    visibility: visible;
  }

  .route-network-map-container {
    width: 100%;
    height: 100%;

    .distance-container {
      position: absolute;
      text-align: center;
      margin-top: 10px;
      z-index: 1;
      font-size: 15px;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;

      pre:empty {
        padding: 0px;
      }

      pre {
        max-width: 200px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        border-radius: 4px;
        padding: 6px 10px;
      }
    }
  }
}
