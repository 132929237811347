@use "../../global-styles/colors";

.tree-view-check-box {
  flex: 1;
  padding: 15px 10px;
  border: 1px solid colors.$border-color;
  border-radius: 4px;
  overflow-y: auto;

  .node-selection-row {
    display: flex;
    gap: 10px;
    background-color: colors.$lighter-grey;
    padding: 5px;
    border: 1px solid colors.$border-color;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;

    &:last-child {
      border-bottom-left-radius: 4px;
    }

    p {
      flex: 1;
    }

    p:last-child {
      min-width: 350px;
      flex-direction: row-reverse;
    }

    .expand-action {
      padding: 2px;
      // We give it a static width, so it does not move other elements when changed
      width: 18px;

      &:hover {
        cursor: pointer;
      }

      &.expand-action--disabled {
        cursor: not-allowed;
        color: colors.$grey;
      }
    }
  }

  .node-block {
    padding-top: 10px;
    padding-left: 20px;
    border-left: 1px dashed colors.$border-color;

    &.node-block-not-expandable {
      padding-left: 50px;
    }

    &:first-child {
      border: none;
      padding-top: 0;
      padding-left: 0;
    }
  }
}
