@use "../../../global-styles/colors";

.feature-information {
  display: flex;
  padding: 15px 10px;
  justify-content: center;
  border-bottom: 1px solid colors.$border-color;

  .feature-informations {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    justify-content: space-around;
    width: 100%;
    font-size: 16px;
  }
}
