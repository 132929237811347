@use "../../../global-styles/colors";
@use "../../../global-styles/settings";

.tab-menu-top {
  .tab-menu-top-container {
    width: 100%;
    height: 0;
    overflow-y: scroll;
    visibility: hidden;

    &.tab-menu-top-container--show {
      height: calc(var(--app-height) - (settings.$top-menu-height + 37px));
      animation: 0.1s fadeIn;
      animation-fill-mode: forwards;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .tab-menu-top-menu {
    display: flex;

    .tab-menu-top-menu_item {
      padding: 10px;
      flex: 1;
      text-align: center;
      border-bottom: 1px solid colors.$border-color;
      border-right: 1px solid colors.$border-color;
      font-weight: bold;

      &.tab-menu-top-menu_item--selected {
        background-color: colors.$select-color;
      }

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
