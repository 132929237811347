.icon-information-menu {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:hover .icon-information-menu-container {
    visibility: visible;
  }

  .icon-information-menu-container {
    z-index: 1;
    position: absolute;
    top: -10px;
    right: 150%;
    font-size: 16px;
    line-height: 1.5;
    background-color: white;
    border: 1px solid black;
    padding: 10px 10px;
    border-radius: 4px;
    display: inline-block;
    visibility: hidden;
    min-width: 200px;
  }
}
