.diagram-wrapper {
  animation: fadein 0.1s;

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    95% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
