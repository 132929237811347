@use "../../global-styles/colors";

.select-menu-svg-icon {
  display: flex;

  .select-menu-svg-icon-select-list-container {
    position: absolute;
    margin-top: 30px;
    z-index: 1;
    background: white;
    right: 13px;
    border: 1px solid colors.$border-color;
    padding: 5px 5px 0px 5px;
    width: 50px;
    border-radius: 4px;
  }

  .select-menu-svg-icon-button {
    &:hover {
      cursor: pointer;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  .select-menu-svg-icon-select-list {
    border-radius: 4px;
    white-space: nowrap;
    user-select: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .selected-menu-svg-icon-button {
    flex: 1;
    width: 27px;
    height: 20px;

    &:hover {
      cursor: pointer;
    }
  }
}
