@use "../../global-styles/colors";

.select-list-view {
  font-size: 14px;
  width: 100%;
  overflow: auto;

  .select-list-view-title {
    margin-bottom: 15px;

    h2 {
      font-size: 26px;
    }
  }

  .select-list-view-grid {
    display: grid;
    grid-auto-columns: minmax(0, 3fr);
    grid-auto-flow: column;
  }

  .select-list-view-content {
    background-color: colors.$background-color;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .select-list-view-header {
      background-color: colors.$hover-color;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid colors.$border-color;

      .select-list-view-header-item {
        flex: 1;
        border-right: 1px solid colors.$border-color;
        font-weight: bold;

        &:last-of-type {
          border-right: none;
        }

        p {
          padding: 8px;
          white-space: wrap;
          overflow: hidden;
        }
      }
    }

    .select-list-view-body {
      border: 1px solid colors.$border-color;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top: none;

      &.select-list-view-body--max-height {
        overflow-y: auto;
        overflow-y: overlay;
      }

      &:empty {
        border: none;
      }

      .select-list-view-body-row {
        border-bottom: 1px solid colors.$border-color;

        &:hover {
          cursor: pointer;
          background-color: colors.$hover-color;
        }

        &:last-of-type {
          border-bottom: none;
        }
      }

      .select-list-view-body-item {
        border-right: 1px solid colors.$border-color;

        &.selected {
          background-color: colors.$select-color;
        }

        &:last-of-type {
          border-right: none;
        }

        p {
          overflow: auto;
          padding: 8px;
        }
      }
    }
  }
}
