.information-control-button {
  border-radius: 4px;
  border: none;
  background-color: white;
  border-bottom: 1px solid #e3e3e3;
  height: 29px;
  width: 29px;

  &.active {
    color: #33b5e5;
  }

  &:hover {
    cursor: pointer;
  }
}

.information-control-container {
  padding-top: 30px;
}

.information-control-body {
  overflow-y: auto;
  max-height: 600px;
  max-width: 500px;
}

.information-control-body-item {
  border-bottom: 1px solid #e3e3e3;
  padding: 15px;

  &:first-of-type {
    padding-top: 0px;
  }

  &:last-child {
    border-bottom: none;
  }
}
