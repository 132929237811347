@use "../../global-styles/colors";
@use "../../global-styles/settings";

.side-menu {
  margin-top: settings.$top-menu-height;
  background-color: white;
  height: var(--app-height);
  position: fixed;
  width: 100%;
  left: -100%;
  transition: all 0.3s linear;
  transform: translateX(-100%);
  border-right: 1px solid colors.$border-color;
  z-index: 1;

  .side-menu__label {
    flex: 1;
  }

  &.show {
    transition: all 0.3s linear;
    transform: translateX(100%);
  }

  .side-menu-item {
    font-size: 16px;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid colors.$border-color;

    &:hover {
      background-color: colors.$hover-color;
    }

    &.side-menu-item--selected {
      background-color: colors.$select-color;
      font-weight: bold;
    }

    .side-menu-item-icon {
      margin-right: 15px;
    }

    a {
      color: black;
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: block;
      padding: 15px 0px 15px 20px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .side-menu {
    background-color: white;
    height: var(--app-height);
    position: fixed;
    width: settings.$side-menu-size;
    left: -(settings.$side-menu-size);
    transition: all 0.3s linear;
    transform: translateX(-(settings.$side-menu-size));
    z-index: 1;

    .side-menu__label {
      flex: 1;
    }

    &.show {
      transition: all 0.3s linear;
      transform: translateX(settings.$side-menu-size);
    }
  }
}
